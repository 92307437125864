export const GEO = [
  { label: 'United States', value: '1242813' },
  { label: 'Canada', value: '1242619' },
  { label: 'United Kindom', value: '1242657' },
  { label: 'Mexico', value: '1242736' },
  { label: 'Australia', value: '1242596' },
  { label: 'New Zealand', value: '1242751' },
  { label: 'Germany', value: '1242636' },
  { label: 'France', value: '1242655' },
  { label: 'Africa', value: 'africa' },
  { label: 'South Africa', value: 'south_africa' },
  { label: 'Worldwide', value: 'ALL' },
];

export const AGE = [
  { value: '11398', label: '18-24' },
  { value: '11399', label: '25-34' },
  { value: '11400', label: '35-44' },
  { value: '11401', label: '45-54' },
  { value: '11402', label: '55-64' },
  { value: '11403', label: '65+' },
];

export const HOUSEHOLD_INCOME = [
  { value: '11427', label: '0-25k' },
  { value: '11422', label: '25k-60k' },
  { value: '11423', label: '50k-75k' },
  { value: '11424', label: '75k-100k' },
  { value: '11421', label: '100k+' },
  { value: '11425', label: '150k-250k' },
  { value: '11426', label: '250k+' },
];

export const GENDER = [
  { value: '11395', label: 'Male' },
  { value: '11396', label: 'Female' },
];

export const LOGS = {
  TOO_MANY_CHARACTERS_TITLE: 'Too Many Characters on Title',
  TOO_MANY_CHARACTERS_SUBTITLE: 'Too Many Characters Subtitle',
  INVALID_RSS_FEED: 'Invalid RSS Feed',
  CREDIT_CARD_INVALID_NUMBER: 'Invalid Card Number',
  CREDIT_CARD_EXPIRED: 'Credit Card Expired',
  CREDIT_CARD_INCORRECT_CVV: 'Credit Card Incorrect CVV',
  CAMPAIGN_RUN: 'Campaign Run',
  ABANDONED: 'Abandoned',
  INTERNAL_SERVER_ERROR: 'Internal Server Error',
};
