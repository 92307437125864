import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import Rellax from 'rellax';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import Popup from 'reactjs-popup';

import { getRssFeed } from '../../api/rss';

import airpodsRight from '../../assets/images/gifs/airpod-right.gif';
import airpodsLeft from '../../assets/images/gifs/airpod-left.gif';
import {
  boostInput,
  resize,
  getPartner,
  parseSessionStorage,
  adjustDownloadPrice,
} from '../../utils/utils';
import { createCampaign, updateCampaign } from '../../api/campaigns';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { signUp } from '../../api/auth';
import { payment } from '../../api/payment';
import Header from '../../components/Header/Header';
import { createStackAdaptCampaign } from '../../api/stackadapt';
import { Grid } from '@material-ui/core';
import TermsOfUse from '../../components/Footer/TermsOfUse';
import { attachDiscountToUser, getUserCard } from '../../api/users';
import { getReferrer } from '../../api/referrers';
import { validationSchema } from './validationSchema';
import { initialValues } from './initialValues';
import Advanced from '../../components/Advanced';
import CompressText from '../../components/CompressText';
import { useSuggestion } from '../../contexts/SuggestionContext';
import { StackAdaptPixel } from '../../utils/stackAdapt';
import { createErrorsLog } from '../../api/errorsLog';
import { useSessionStorageLogger } from '../../hooks/useSessionStorageLogger';
import { LOGS } from '../../utils/consts';
import { RangeSlider } from '../../components/RangeSlider/RangeSlider';
import CreditCard from '../../components/CreditCard/CreditCard';
import EpisodeDescription from '../../components/EpisodeDescription/EpisodeDescription';

const Modal = () => (
  <Popup
    trigger={open => (
      <button type="button" className="temrs-of-use">
        the terms of use
      </button>
    )}
    position="center center"
    modal
    closeOnDocumentClick
    lockScroll
  >
    {close => (
      <>
        <button type="button" className="close" onClick={close}>
          &times;
        </button>
        <TermsOfUse />
      </>
    )}
  </Popup>
);

const customMessageForRefer = {
  podfest: {
    title: 'Podfest discount',
    message: `Congrats on using the Podfest discount code! When you spend $100 you will get $120 worth of podcast downloads! (The normal price is $0.90, but you get your downloads for $.75) We’ll leave this up for a bit for our podfest friends, so be sure to use it a few times.`,
  },
  hubspot: {
    title: 'mowPod Boost + Hubspot',
    message: `Welcome to mowPod Boost in partnership with the Hubspot Podcast Network! Here you can get Boost at the discounted rate of $0.72 per download.`,
  },
};

const { partnerName, costPerDownload, url = '', howThisWorks, textBottom = '' } = getPartner();
export default function SignUp() {
  const [openEpisodes, setOpenEpisodes] = useState(false);
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useCurrentUser();
  const history = useHistory();
  const textAreaRef = useRef(null);
  const [feed, setFeed] = useState({});
  const [savedPaymentMethod, setSavedPaymentMethod] = useState();
  const [discount, setDiscount] = useState();
  const [rssWithNewEpisode, setRssWithNewEpisode] = useState();
  const [paymentData, setPaymentData] = useState({});
  const location = useLocation();
  const { getSuggestions, setTitles, setSubtitles } = useSuggestion();
  const [campaign, setCampaign] = useState(null);
  const formik = useFormik({
    initialValues: { ...initialValues, logined: !!user },
    validationSchema,
    validateOnChange: false,
    onSubmit: async values => {
      try {
        setLoading(true);
        let isNewUser = false;
        if (!user) {
          const { email, password } = values;
          const newUser = await signUp({ email, password, source: partnerName });
          setUser(newUser);
          isNewUser = true;
        }

        if (discount) {
          await attachDiscountToUser(discount._id);
        }

        const { boost } = values;

        if (!values.episode.itunes.image) {
          values.episode.itunes.image = feed.itunes.image;
        }

        let paymentResponse = {};
        if (!paymentData.id) {
          paymentResponse = await payment({
            ...values,
            boost,
          });
          setPaymentData(paymentResponse);
        }

        if (paymentResponse.status === 'succeeded' || paymentData.status === 'succeeded') {
          let createdCampaign;
          const fields = {
            ...values,
            boost,
            paymentId: paymentResponse.id,
            referrer: discount?._id,
            price: adjustDownloadPrice(discount?.price || costPerDownload, boost),
          };
          if (!campaign) {
            createdCampaign = await createCampaign(fields);
          } else {
            await updateCampaign(fields);
            createdCampaign = campaign;
          }

          logCampaignRunResponse(true);

          setCampaign(createdCampaign);
          await createStackAdaptCampaign({ campaignId: createdCampaign._id, isNewUser });
          history.push(`/thank-you?campaignId=${createdCampaign._id}`);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        toast.error(error.response.data?.message);

        logCreditCardResponseError(error.response.data?.message);

        if (error.response.status === 500) {
          logInternalServerError(LOGS.INTERNAL_SERVER_ERROR);
        }
      }
    },
  });

  const {
    logCreditCardResponseError,
    logRssResponseError,
    logCampaignRunResponse,
    campaignRunResponse,
    logInternalServerError,
    internalServerError
  } = useSessionStorageLogger(formik, user);

  useEffect(() => {
      !campaignRunResponse && !user && sessionStorage.clear();
  }, [campaignRunResponse, user])

  useEffect(() => {
    const sendLogsWithInternalError = async () => {
      await createErrorsLog(parseSessionStorage());
      sessionStorage.clear();
    };

    internalServerError && sendLogsWithInternalError();
  }, [internalServerError]);

  useEffect(() => {
    const sendLogsOnCampaignRun = async () => {
      await createErrorsLog(parseSessionStorage());
      sessionStorage.clear();
    };

    campaignRunResponse && sendLogsOnCampaignRun();
  }, [campaignRunResponse]);

  useEffect(() => {
    const sendLogsBeforeUnload = async event => {
      setTimeout(async () => {
        await createErrorsLog(parseSessionStorage(true));
      }, 0)

    };

    window.addEventListener('beforeunload', sendLogsBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', sendLogsBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const rellax = new Rellax('.rellax', {
      center: true,
    });
    if (user?.referrer && user?.referrer.active) {
      setDiscount(() => user.referrer);
    }
    if (params.has('rss')) {
      setRssWithNewEpisode(params.get('rss'));
    }
    if (params.has('referrer')) {
      getReferrer(params.get('referrer'))
        .then(response => {
          setDiscount(response);
        })
        .catch(e => {});
    }
    getUserCard()
      .then(result => {
        setSavedPaymentMethod(result);
        formik.setFieldValue('useSavedPaymentMethod', !!result);
        formik.setFieldValue('cardTypeForSavedPayment', result?.brand);
      })
      .catch(e => {
        console.error(e);
      });
    window.scrollTo(0, 0);
    StackAdaptPixel();
    return () => {
      rellax.destroy();
    };
  }, []);

  useEffect(() => {
    if (rssWithNewEpisode) {
      formik.setFieldValue('rss', rssWithNewEpisode);
    }
  }, [rssWithNewEpisode]);

  useEffect(() => {
    formik.setFieldValue('logined', !!user);
  }, [user]);

  useEffect(() => {
    if (formik.values.rss) {
      formik.validateField('rss');
      const timeOutId = setTimeout(() => {
        setLoading(true);
        if (formik.errors.rss) {
          setLoading(false);
          setEpisodes([]);
          const { rss, boost, ...rest } = initialValues;
          formik.setValues({ ...formik.values, ...rest });
          return;
        }
        return getRssFeed(formik.values.rss)
          .then(result => {
            const { items, ...rest } = result;
            setLoading(false);
            setEpisodes(items);
            setFeed(rest);
            formik.setFieldValue('episode', items[0]);
            formik.setFieldValue('title', items[0].title || '');
            formik.setFieldValue(
              'subtitle',
              items[0].itunes.subtitle || items[0].itunes.summary || items[0].contentSnippet || ''
            );
          })
          .catch(e => {
            setLoading(false);
            formik.setFieldValue('episode', {});
            setEpisodes([]);
            console.log('e :>> ', e);
            toast.error(`RSS Feed Link: ${e.response.statusText}`);

            logRssResponseError(true);
          });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      setEpisodes([]);
      const {
        rss,
        email,
        password,
        cardNumber,
        cardDate,
        cardCvv,
        cardName,
        zipCode,
        boost,
        useSavedPaymentMethod,
        ...rest
      } = initialValues;
      formik.setValues({ ...formik.values, ...rest });
    }
  }, [formik.errors.rss, formik.values.rss]);

  useEffect(() => {
    if (textAreaRef.current) {
      resize(textAreaRef.current);
    }
  }, [textAreaRef, formik.values.subtitle]);

  useEffect(() => {
    if (!formik.isSubmitting && Object.keys(formik.errors).length) {
      Object.keys(formik.errors).forEach(item => toast.error(formik.errors[item]));
    }
  }, [formik.isSubmitting]);

  const getSubtitle =
    formik.values?.episode?.itunes?.subtitle ||
    formik.values?.episode?.itunes?.summary ||
    formik.values?.episode?.contentSnippet;

  useEffect(() => {
    setTitles(null);
    setSubtitles(null);
    if (formik.values.episode.title) {
      getSuggestions(getSubtitle);
    }
  }, [formik.values.episode]);

  const handleRssFeed = e => {
    const { value } = e.target;
    formik.setFieldValue('rss', value);
  };

  const reCaptcha = value => {
    formik.setFieldValue('reCaptcha', !!value);
  };

  const handleEpisode = item => {
    formik.setFieldValue('episode', item);
    formik.setFieldValue('title', item.title || '');
    formik.setFieldValue(
      'subtitle',
      item.itunes.subtitle || item.itunes.summary || item.contentSnippet || ''
    );
  };

  const handleBoost = value => {
    if (!value) {
      return formik.setFieldValue('boost', 100);
    }
    return formik.setFieldValue('boost', value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await formik.submitForm();
    setLoading(false);
  };

  return (
    <>
      <div className="backgrounds">
        <div className="background__rainbow">
          <div className="background__rainbow--image rainbow--first rellax" />
          <div className="background__rainbow--image rainbow--second rellax" />
          <div className="background__rainbow--image rainbow--third rellax" />
        </div>
        <div className="background-sun" />
      </div>
      <Header />
      <div className="boost-page title">
        <h1 className="rotate" data-aos="fade-up" data-aos-delay="500" data-aos-duration="300">
          Get more IAB2.0 <br />
          downloads to your podcast!
        </h1>
      </div>
      <main>
        <img className="airpod-right rellax" data-rellax-speed="2" src={airpodsRight} alt="airpod-right" />
        <img className="airpod-left rellax" data-rellax-speed="2" src={airpodsLeft} alt="airpod-left" />
        <FormikProvider value={formik}>
          <form className="main container" autoComplete="off" onSubmit={formik.handleSubmit}>
            <div className="title-header">
              <div className="title-form">
                {customMessageForRefer[discount?.code.toLowerCase()] && (
                  <div>
                    <label className="orange-text">
                      {customMessageForRefer[discount?.code.toLowerCase()].title}
                    </label>
                    <div className="how-this-works">
                      {customMessageForRefer[discount?.code.toLowerCase()].message}
                    </div>
                  </div>
                )}
                <div>
                  <label className="orange-text">How this works?</label>
                  <div className="how-this-works">{howThisWorks}</div>
                </div>
                <div>
                  <label htmlFor="title-form__input-rss" className="orange-text">
                    RSS Feed Link
                  </label>
                  <div className="position-relative">
                    <Field
                      type="url"
                      className="title-form__input-rss"
                      onChange={handleRssFeed}
                      id="title-form__input-rss"
                      name="rss"
                      placeholder={url || 'https://example.com/your-podcast/'}
                      pattern="https?://.*"
                      size="30"
                    />
                    {loading && <FontAwesomeIcon className="loading-spin" icon={faSpinner} size="lg" spin />}
                  </div>
                  {formik.errors.rss && <div className="fieldError errorColor">{formik.errors.rss}</div>}
                  {!!episodes.length && (
                    <>
                      <label htmlFor="title-form__input-episode" className="orange-text">
                        Episode
                      </label>
                      <div className="episodes-list" onClick={() => setOpenEpisodes(!openEpisodes)}>
                        <button type="button" className="dropdown_button">
                          {formik.values.episode.title || ''}
                        </button>
                        <nav id="episodes_id" className={cx('episodes', { 'show-episodes': openEpisodes })}>
                          {episodes.map((item, i) => (
                            <li key={i} onClick={() => handleEpisode(item)}>
                              {item.title}
                            </li>
                          ))}
                        </nav>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {formik.values.episode.title && (
                <>
                  <EpisodeDescription formik={formik} feed={feed}/>
                  <div>
                    <section className="title-form title-form-text">
                      <label htmlFor="title-form__input-title" className="orange-text">
                        Title
                      </label>
                      <ErrorMessage name="title">
                        {msg => <div className="fieldError errorColor">{msg}</div>}
                      </ErrorMessage>
                      <div className="position-relative title-form-text">
                        <Field
                          as="textarea"
                          name="title"
                          className="title-form__input-title"
                          id="title-form__input-title"
                          placeholder="Enter podcast title"
                        />
                        <span
                          className={cx('title-form__input-title--display', {
                            errorColor: formik.values.title.length > 46,
                          })}
                        >
                          {formik.values.title.length}/46
                        </span>
                      </div>
                      <Grid
                        className="suggestions"
                        container
                        item
                        md={12}
                        alignItems="flex-start"
                        justifyContent="flex-end"
                      >
                        <CompressText
                          text={getSubtitle}
                          originalText={formik.values.episode.title}
                          numberOfCharacters={46}
                          handleOption={title => formik.setFieldValue('title', title)}
                          type="title"
                        />
                      </Grid>
                      <Grid container item md={12} justifyContent="space-between" alignItems="center">
                        <label htmlFor="title-form__input-subtitle" className="orange-text">
                          Subtitle
                        </label>
                      </Grid>
                      <ErrorMessage name="subtitle">
                        {msg => <div className="fieldError errorColor">{msg}</div>}
                      </ErrorMessage>
                      <div className="position-relative">
                        <Field
                          as="textarea"
                          name="subtitle"
                          innerRef={textAreaRef}
                          value={formik.values.subtitle}
                          className="title-form__input-subtitle"
                          id="title-form__input-subtitle"
                          placeholder="Enter podcast subtitle"
                        />
                        <span
                          className={cx('title-form__input-subtitle--display', {
                            errorColor: formik.values.subtitle.length > 120,
                          })}
                        >
                          {formik.values.subtitle.length}/120
                        </span>
                      </div>
                      <Grid className="suggestions" container item xs={12} justifyContent="flex-end">
                        <CompressText
                          text={getSubtitle}
                          numberOfCharacters={120}
                          handleOption={subtitle => formik.setFieldValue('subtitle', subtitle)}
                          type="subtitle"
                        />
                      </Grid>
                    </section>
                    <Advanced formik={formik} />
                  </div>
                </>
              )}
            </div>
            <div className="boost">
              <div className="boost__container">
                <div className="boost__counter">
                  <p className="boost__counter--value orange-text">${formik.values.boost}</p>
                  <div className="boost__counter--minmax">
                    <span>$100</span><span>$1000</span>
                  </div>
                  <RangeSlider min={100} max={1000} onChange={handleBoost} />
                  <span>
                    $
                    {discount?.price
                      ? adjustDownloadPrice(discount.price.toFixed(2), formik.values.boost)
                      : adjustDownloadPrice(costPerDownload, formik.values.boost)}
                    per download ={' '}
                    <span className="boost__counter--sum orange-text">
                      <br />
                      {boostInput(formik.values.boost, discount?.price)} Downloads
                    </span>
                  </span>
                  <ErrorMessage name="boost">
                    {msg => <div className="fieldError errorColor">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>
            <CreditCard savedPaymentMethod={savedPaymentMethod} loading={loading} formik={formik} user={user}/>
            {!user && (
              <div className="card__login">
                <div className="card-input--login">
                  <div className="card-input--mail">
                    <label htmlFor="user_login">Email address</label>
                    <Field type="email" name="email" id="user_login" placeholder="email" />
                    <ErrorMessage name="email">
                      {msg => (
                        <div className="fieldError errorColor" style={{ margin: '0 10px' }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="card-input--password">
                    <label htmlFor="user_password">Password</label>
                    <Field type="password" name="password" id="user_password" placeholder="•••••••••••••••" />
                    <ErrorMessage name="password">
                      {msg => (
                        <div className="fieldError errorColor" style={{ margin: '0 10px' }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            )}
            <div className="submit-boost">
              <h1>
                Total to be billed:{' '}
                <span className="boost-sum orange-text">
                  <br />
                  {formik.values.boost}
                </span>
              </h1>
              <span className="submit-boost--description">
                Boost will go into queue once and is expected to go live within a few hours. Cancellations
                MUST be done within 60 minutes of purchase by emailing{' '}
                <a href="mailto:info@mowmedia.com">info@mowmedia.com</a> to obtain a full refund, during
                regular business hours of 9am-5pm EST. You will be billed for all downloads received if you
                cancel during the duration of the campaign, or for campaigns running after business hours.{' '}
                {textBottom ||
                  `Downloads will appear as mozilla, chrome, or mowpod.com in your${
                    partnerName === 'megaphone' ? ' Megaphone ' : ' host '
                  } and chartable statistics. `}
                There are no refunds for completed campaigns. This page serves as your Ad Insertion Order
                (IO). You must also review and acknowledge that you understand and agree to the Terms Of Use
                below.
              </span>
              <div className="agree-terms">
                <div className="checkbox--container">
                  <div className="agree-terms--checkbox">
                    <Field type="checkbox" name="agreeTerms" id="agree-terms--checkbox" />
                    <label htmlFor="agree-terms--checkbox"></label>
                  </div>
                  <span>
                    I understand <Modal />
                  </span>
                  <ErrorMessage name="agreeTerms">
                    {msg => (
                      <div className="fieldError errorColor" style={{ margin: '0 10px' }}>
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>

                <ReCAPTCHA
                  sitekey="6LfZOWcgAAAAAB0_xFw_RDaz5Y1r9eO5zYslsIDd"
                  onChange={reCaptcha}
                  theme="dark"
                />
                <button className="submit-boost--button submit-button" onClick={handleSubmit} disabled={loading}>
                  {loading ? <FontAwesomeIcon icon={faSpinner} size="2x" spin /> : 'Boost now'}
                </button>
                {loading && <h3>Creating Campaign - Stay On This Page!</h3>}
              </div>
            </div>
          </form>
        </FormikProvider>
      </main>
    </>
  );
}
